import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async getAvailableItems(data, pageable) {
    const result = await axios.post(endpoints.inventory.Controller.getAvailableItems(pageable), data)
    return result.data.body
  },
  async createReservation(data) {
    const result = await axios.post(endpoints.fbm.FbmItemReservationController.createReservation(), data)
    return result.data.body
  },
  async getReservedItems(fbmOrderItemId) {
    const result = await axios.get(endpoints.fbm.FbmItemReservationController.getReservedItems(fbmOrderItemId))
    return result.data.body
  },
  async deleteReservation(id) {
    const result = await axios.delete(endpoints.fbm.FbmItemReservationController.deleteReservation(id))
    return result.data.body
  },
}
